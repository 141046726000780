<template>
  <div>
    <button
      class="wishlist-button"
      :class="[computedButtonClass, computedWidthClass]"
      :data-cy="buttonDataCy"
      @click="toggleWishlist"
    >
      <span class="wishlist-button__text">{{ buttonText }}</span>
      <Icon :name="iconName" :class="iconClass" class="u-margin-left--0-5" />
    </button>
    <WishlistButtonModal
      :show-modal="showModal"
      :signup-modal="signupModal"
      :wishlist-view-modal="wishlistViewModal"
      :remove-trip-modal="removeTripModal"
      @closeModal="closeModal"
      @removeWishlistItem="removeWishlistItem"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import { TokenableScheme } from "@nuxtjs/auth-next";
import { WishlistButtonProps, AddToWishlistResultProp } from "./Props";
import { WishlistItems } from "~~/lib/types/Salesforce/Wishlist";
import { fetchParser } from "~~/lib/utils/datetime/dateTimeReviver";
import { Contact } from "~~/lib/types/Salesforce/Contact";
import { getItemFromLocalStorage } from "~~/lib/utils/manageLocalStorageItems";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Component],
});

export default Vue.extend({
  name: "WishlistButton",
  components: {
    Icon,
    WishlistButtonModal: () => import("./WishlistButtonModal.vue"),
  },
  props: {
    productCode: {
      type: String as PropType<WishlistButtonProps["productCode"]>,
      required: false,
      default: undefined,
    },
    productName: {
      type: String as PropType<WishlistButtonProps["productName"]>,
      required: false,
      default: undefined,
    },
    fullWidth: {
      type: Boolean as PropType<WishlistButtonProps["fullWidth"]>,
      required: false,
      default: false,
    },
  },
  data(): {
    showModal: boolean;
    signupModal: boolean;
    wishlistViewModal: boolean;
    removeTripModal: boolean;
    loggedIn: boolean;
    wishlisted: boolean;
  } {
    return {
      showModal: false,
      signupModal: false,
      wishlistViewModal: false,
      removeTripModal: false,
      loggedIn: false,
      wishlisted: false,
    };
  },
  computed: {
    isWishlisted() {
      return this.wishlisted;
    },
    computedButtonClass() {
      return this.wishlisted ? "wishlist-button--add" : "";
    },
    computedWidthClass() {
      return this.fullWidth ? "button--block" : "";
    },
    iconClass() {
      return this.wishlisted ? "icon--secondary" : "";
    },
    buttonDataCy() {
      return this.wishlisted
        ? "wishlist-button__remove-from-wishlist"
        : "wishlist-button__add-to-wishlist";
    },
    buttonText() {
      return this.wishlisted ? "Added to wishlist" : "Add to my wishlist";
    },
    iconName() {
      return this.wishlisted ? "heart" : "heart-outline";
    },
  },
  mounted() {
    this.setWishlistedStatus();
  },
  methods: {
    async addToWishlist() {
      await this.addWishlistItemToStore();
      this.wishlisted = true;
      try {
        if (this.loggedIn) {
          this.wishlistViewModal = true;
          const contact = (await this.getSalesforceContact()) as Contact;
          if (contact) {
            const result = (await this.addWishlistItemToSalesforce(
              contact
            )) as AddToWishlistResultProp;

            if (result && result.errorCode !== "DUPLICATE_VALUE") {
              await this.updateWishlistItemId(result.id);
            }
          }
        } else {
          this.signupModal = true;
        }
      } catch (error) {
        logger.error("Adding wishlist item to salesforce failed", error);
      }
    },
    async getSalesforceContact() {
      return await $fetch("/api/nuxt/salesforce/contact", {
        params: {
          correlationID: getItemFromLocalStorage("auth0_id"),
        },
        parseResponse: fetchParser,
      });
    },
    async addWishlistItemToSalesforce(contact: Contact) {
      return await $fetch("/api/nuxt/wishlist/add-item", {
        method: "POST",
        body: {
          productCode: this.productCode,
          productName: this.productName,
          contactID: contact.contactID,
        },
      });
    },
    async toggleWishlist() {
      this.setLoggedInStatus();
      this.showModal = true;
      if (this.isWishlisted) {
        this.removeTripModal = true;
      } else {
        await this.addToWishlist();
      }
    },
    closeModal(): void {
      this.signupModal = false;
      this.wishlistViewModal = false;
      this.removeTripModal = false;
      this.showModal = false;
    },
    async addWishlistItemToStore(): Promise<void> {
      try {
        const wishlistItems: WishlistItems =
          this.$store.getters["wishlist/getWishlistItems"] || {};
        if (!this.isWishlisted) {
          wishlistItems[this.productCode] = {
            productCode: this.productCode,
            id: null,
            productName: this.productName,
          };
          await this.$store.dispatch("wishlist/setWishlist", wishlistItems);
        }
      } catch (error) {
        logger.error("updating wishlist in local storage failed", error);
      }
    },
    setWishlistedStatus(): void {
      this.wishlisted = false;

      const wishlistUpdatedStatus = this.$store.getters["wishlist/getStatus"];
      if (wishlistUpdatedStatus === false) {
        this.$store.dispatch("wishlist/initialize").catch((err) =>
          logger.error(
            "An exception in dispatching from store: setWishlistedStatus",
            {
              error: err,
              functionName: "setWishlistedStatus",
            }
          )
        );
      }

      const wishlistItems = this.$store.getters["wishlist/getWishlistItems"];
      this.wishlisted =
        wishlistItems && wishlistItems[this.productCode] !== undefined;
    },
    async removeWishlistItem() {
      try {
        if (this.wishlisted) {
          const wishlistItems =
            this.$store.getters["wishlist/getWishlistItems"];
          const itemId = wishlistItems[this.productCode].id;
          if (this.loggedIn && itemId !== null) {
            await $fetch("/api/nuxt/wishlist/remove-item", {
              method: "PUT",
              body: {
                wishlistId: itemId,
              },
            });
          }
          delete wishlistItems[this.productCode];
          this.wishlisted = false;
          await this.$store.dispatch("wishlist/setWishlist", wishlistItems);
          this.$emit("status", this.wishlisted);
          this.showModal = false;
          this.removeTripModal = false;
        }
      } catch (error) {
        logger.error("Removing item from wishlist failed", error);
      }
    },
    async updateWishlistItemId(itemId: string) {
      if (this.wishlisted) {
        const wishlistItems = this.$store.getters["wishlist/getWishlistItems"];
        wishlistItems[this.productCode] = {
          productCode: this.productCode,
          id: itemId,
          productName: this.productName,
        };
        await this.$store.dispatch("wishlist/setWishlist", wishlistItems);
      }
    },
    setLoggedInStatus() {
      const strategy = this.$auth?.strategy as TokenableScheme;

      this.loggedIn =
        strategy.token?.status().valid() &&
        !strategy?.token?.status().expired();
    },
  },
});
</script>
<style lang="scss">
@import "./wishlist-button";
</style>

<template>
  <ProductCard
    v-bind="{
      ...$props,
      url: $link.create($props.url),
      routerLink: $link.getIsAllowedAsNuxtLinks($props.url),
      currencyLocale: getLocaleByCode($i18n.locale).currencyLocale,
    }"
    data-cy="trip-card"
  >
    <template slot="utilities">
      <WishlistButton
        :product-code="code"
        :product-name="name"
        :full-width="true"
      />
    </template>
  </ProductCard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import ProductCard from "atlas/src/components/ProductCard/ProductCard.vue";
import { TripCardProps } from "./Props";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";
import WishlistButton from "~/components/WishlistButton/WishlistButton.vue";
export default Vue.extend({
  name: "TripCard",
  components: {
    ProductCard,
    WishlistButton,
  },
  props: {
    name: {
      type: String as PropType<TripCardProps["name"]>,
      required: false,
      default: null,
    },
    code: {
      type: String as PropType<TripCardProps["code"]>,
      required: false,
      default: null,
    },
    duration: {
      type: Number as PropType<TripCardProps["duration"]>,
      required: false,
      default: null,
    },
    styles: {
      type: Array as PropType<TripCardProps["styles"]>,
      required: false,
      default: null,
    },
    themes: {
      type: Array as PropType<TripCardProps["themes"]>,
      required: false,
      default: null,
    },
    destinations: {
      type: Array as PropType<TripCardProps["destinations"]>,
      required: false,
      default: null,
    },
    price: {
      type: Object as PropType<TripCardProps["price"]>,
      required: false,
      default() {
        return {};
      },
    },
    discountPrice: {
      type: Object as PropType<TripCardProps["discountPrice"]>,
      required: false,
      default() {
        return {};
      },
    },
    startDate: {
      type: Date as PropType<TripCardProps["startDate"]>,
      required: false,
      default: null,
    },
    url: {
      type: String as PropType<TripCardProps["url"]>,
      required: false,
      default: null,
    },
    map: {
      type: Object as PropType<TripCardProps["map"]>,
      required: false,
      default() {
        return {};
      },
    },
    showPrice: {
      type: Boolean as PropType<TripCardProps["showPrice"]>,
      required: false,
      default: false,
    },
    showDecimalPlaces: {
      type: Boolean as PropType<TripCardProps["showDecimalPlaces"]>,
      required: false,
      default: false,
    },
    mini: {
      type: Boolean as PropType<TripCardProps["mini"]>,
      required: false,
      default: false,
    },
    type: {
      type: String as PropType<TripCardProps["type"]>,
      default: "default",
      validator: (value: string) => {
        return ["default", "mini", "utility"].includes(value);
      },
    },
    routerLink: {
      type: Boolean as PropType<TripCardProps["routerLink"]>,
      required: false,
      default: false,
    },
  },
  methods: { getLocaleByCode },
});
</script>

const iso8601DateTimeRegex =
  /^([0-9]{4})-?(1[0-2]|0[1-9])-?(3[01]|0[1-9]|[12][0-9])[ |T](2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/;

/**
 * This method is intended for use as the second argument in JSON.parse,
 * in order to convert strings matching the above regex (ISO8601 Date & Time)
 * back into javascript dates
 * @param _key object property key
 * @param value object property value
 * @returns The original value or a javascript Date if it looks like a date string
 */
const dateTimeReviver = (_key: string, value: unknown): Date | unknown => {
  if (typeof value === "string" && iso8601DateTimeRegex.exec(value)) {
    return new Date(value);
  }

  return value;
};

/**
 * Quick wrapper for use with $fetch, reference this as the value for parseResponse when using $fetch
 * to rehydrate dates
 * @param responseText response body as a string
 * @returns Javascript object with ISO8601 date strings parsed into Date objects
 */
/* istanbul ignore next */
const fetchParser = (responseText: string) =>
  JSON.parse(responseText, dateTimeReviver);

export { dateTimeReviver, fetchParser };
